import axios from "axios";
import Cookies from "js-cookie";
import AuthService from "../services/auth.service";
import { API_URL, removeToken } from "./tokens.api";
import { errorCatch } from "../utils/errorCatch";


export const $axios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use((config) => {
  const accessToken = Cookies.get("access-token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
	(config) => config,
	async (error) => {

		const originalRequest = error.config;

		// (если ошибка 401 || срок действия токенов закончился || нет токенов) && есть конфиг и это не повторная попытка
		if ( (error.response.status === 401 || errorCatch(error) === 'jwt expired' || errorCatch(error) === 'jwt must be provided') && error.config && !error.config._isRetry) {
			originalRequest._isRetry = true;
			try {
				//получаем новые токены
				console.log("получаем новые токены");
				await AuthService.getNewTokens();

				return instance.request(originalRequest)
			} catch (e) {
				console.log("instance.interceptors.response error=",e.message);
				//если ошибка срока действия токенов удаляем токен
				if (errorCatch(e) === 'jwt expired') {
					console.log("ошибка срока действия токенов удаляем токен");
					removeToken();
				}
			}
		}

		throw error
	}
)

export default instance;
