import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import User from "../pages/User/User";
import Messages from "../pages/Messages/Messages";
import MarkupMessage from "../pages/Messages/MarkupMessage/MarkupMessage";

//загружает нужный компонент в зависимости от url
const Router = () => {
    return (
        <Routes>
            <Route path="/auth/login" element={<Auth type={"login"} />} />
            <Route path="/auth/register" element={<Auth type={"register"} />} />
            <Route path="/" element={<User/>}/>
            <Route path="/messages" element={<Messages/>}/>
            <Route path="/messages/:id" element={<MarkupMessage/>}/>
        </Routes>
        );
    };

export default Router;
