import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import { removeToken } from "../../api/tokens.api";
import { UserContext } from "../../providers/UserProvider";
import { useUserRedirect } from "../../utils/useUserRedirect";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import TextField from "@mui/material/TextField/TextField";
import ConfigService from "../../services/config.service";
import CustomSnackbar from "../../components/custom-snackbar/CustomSnackbar";
import {useNavigate} from "react-router";


const User = () => {

  const classes = useStyles();
  const gClasses = useGStyles();
  const { user, setUser } = useContext(UserContext);

  const [toast, setToast] = useState({open: false, severity: "error", "text": ""});
  const [commandData, setCommandData] = useState({command: "", token: ""});

    const nav = useNavigate();

  //если пользователь не авторизован редирект в "/auth/login"
  useUserRedirect();
  if(!user) return null;

  const goToMarkup = () => {
      nav('/messages');
  }

  const onClickLogout = () => {
    setUser(null);
    removeToken();
  };

  const runCommand = async () => {

    const result = await ConfigService.runCommand({...commandData});

    if (!(result && result.status) ) {
      setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка!"});
    }else{
      setToast({open: true, severity: "success", text: "Команда выполнена!"});
    }

  }

  return (
    <div className={clsx(gClasses.wrapper, gClasses.centerFlexContent)}>

      <p>Hello {user?.name}!</p>


        <p>Выполнить команду:</p>

        <TextField
            placeholder="Команда"
            type="text"
            onChange={(e) => setCommandData({...commandData, command: e.target.value})}
            value={commandData.command}
            className={clsx(gClasses.formInput, gClasses.mb16)}
        />

        <TextField
            placeholder="Токен"
            type="text"
            onChange={(e) => setCommandData({...commandData, token: e.target.value})}
            value={commandData.token}
            className={clsx(gClasses.formInput, gClasses.mb16)}
        />

        <Button
            type="button"
            variant="contained"
            disabled={!(commandData.command && commandData.token)}
            onClick={runCommand}
            className={gClasses.mb16}
        >
            Выполнить команду
        </Button>


        <Button
            color="success"
            variant="contained"
            onClick={() => goToMarkup()}
            className={gClasses.mb16}
        >
            Разметка сообщений
        </Button>

      <Button
          color="success"
          variant="outlined"
          onClick={() => onClickLogout()}
      >
        Выход
      </Button>

      <CustomSnackbar toast={toast} setToast={setToast} classes={classes}/>

    </div>
  );
};

export default User;
