import Cookies from "js-cookie";

export const API_URL = `${process.env.REACT_APP_API_URL}`;
console.log('REACT_APP_API_URL: ', API_URL);

export const getTokens = () => {
	//console.log("client getTokens start");
	const accessToken = Cookies.get('access-token');
	const refreshToken = Cookies.get('refresh-token');
	//console.log("client getTokens end tokens accessToken="+!!accessToken+", refreshToken="+!!refreshToken);
	return {accessToken, refreshToken}
}

export const saveTokens = ({ accessToken, refreshToken}) => {
	Cookies.set('access-token', accessToken);
	Cookies.set('refresh-token', refreshToken);
}

export const removeToken = () => {
	Cookies.remove('access-token');
	Cookies.remove('refresh-token');
}