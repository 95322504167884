import { $axios } from "../api/axios.api";
import { saveTokens, getTokens } from "../api/tokens.api";

const AuthService = {

  async updateVerificationCode(params) {
    const { data } = await $axios.post("/auth/update-verification-code", params);
    return data;
  },

  async checkVerificationCode(params) {
    const { data } = await $axios.post("/auth/check-verification-code", params);
    return data;
  },

  async login(login, password) {
    const { data } = await $axios.post("/auth/login", { login, password });
    this.newTokens(data);
    return data.user;
  },

  /*
  async register(login, name, password) {
    const { data } = await $axios.post(`/auth/register`, { login, name, password });
    this.newTokens(data);
    return data.user;
  },
   */

  async getNewTokens() {
    const { refreshToken } = getTokens();
    const { data } = await $axios.post("/auth/access-token", { refreshToken });
    this.newTokens(data);
    return data;
  },

  newTokens(data) {
    if (data?.tokens?.accessToken) {
      saveTokens({
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken,
      });
    }
  },

};

export default AuthService;
