import React, {useContext, useEffect, useState, useRef} from "react";
import useGStyles from "../../assets/global-styles";
import useStyles from "./styles";
import clsx from "clsx";
import MessagesService from "../../services/messages.service";
import CustomSnackbar from "../../components/custom-snackbar/CustomSnackbar";
import InfiniteScroll from 'react-infinite-scroll-component';
import fetchMoreData from "../../utils/fetchMoreData";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MsgItem from "./MsgItem/MsgItem";
import {useLocation, useNavigate} from "react-router";
import moment from 'moment';
import { useUserRedirect } from "../../utils/useUserRedirect";
import {UserContext} from "../../providers/UserProvider";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import InputAdornment from "@mui/material/InputAdornment";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from '@mui/material/Drawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import {AIIcon} from "../../assets/svg-icons";
import MarkupMessage from "./MarkupMessage/MarkupMessage";
import AppBar from "@mui/material/AppBar/AppBar";
import InputBase from '@mui/material/InputBase';
import Toolbar from "@mui/material/Toolbar";
import Paper from '@mui/material/Paper';
import ClearIcon from "@mui/icons-material/Clear";
import Queue from "queue";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField/TextField";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {timeout, replObjInArr} from "../../utils/functions";
import LoadingButton from '@mui/lab/LoadingButton';


let searchText = "";


const qSearch = new Queue({
    results: [],
    autostart: true,
    concurrency: 1,
});



let searchTextNormalized = [];

let dateRequest = new Date();
let fetchMoreLoading = false;

const getTextPeriod = (period) => {

    const convertDate = (number) => {
        switch (number) {
            case 0:
                return "0";
                break;
            case 1:
                return "¼";
                break;
            case 2:
                return "½";
                break;
            case 3:
                return "1";
                break;
            case 4:
                return "2";
                break;
            case 5:
                return "3";
                break;
            case 6:
                return "6";
                break;
            case 7:
                return "∞";
                break;
        }
    }

    if(period[0] === 0){
        return "< "+convertDate(period[1]);
    }

    return convertDate(period[0])+"-"+convertDate(period[1]);

}

const periodFilterMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 1,
        label: '1н',
    },
    {
        value: 2,
        label: '2н',
    },
    {
        value: 3,
        label: '1м',
    },
    {
        value: 4,
        label: '2м',
    },
    {
        value: 5,
        label: '3м',
    },
    {
        value: 6,
        label: '6м',
    },
    {
        value: 7,
        label: '∞',
    },
];

const getDatePeriod = (period) => {

    const convertDate = (number, morning) => {
        switch (number) {
            case 0:
                return null;
                break;
            case 1:
                return (morning?moment().startOf("day").subtract(7, "days").toDate():moment().endOf("day").subtract(7, "days").toDate());
                break;
            case 2:
                return (morning?moment().startOf("day").subtract(14, "days").toDate():moment().endOf("day").subtract(14, "days").toDate());
                break;
            case 3:
                return (morning?moment().startOf("day").subtract(1, "month").toDate():moment().endOf("day").subtract(1, "month").toDate());
                break;
            case 4:
                return (morning?moment().startOf("day").subtract(2, "month").toDate():moment().endOf("day").subtract(2, "month").toDate());
                break;
            case 5:
                return (morning?moment().startOf("day").subtract(3, "month").toDate():moment().endOf("day").subtract(3, "month").toDate());
                break;
            case 6:
                return (morning?moment().startOf("day").subtract(6, "month").toDate():moment().endOf("day").subtract(6, "month").toDate());
                break;
            case 7:
                //∞
                return null;
                break;
            default:
                return null;
                break;
        }
    }

    let dates = {
        startDate: convertDate(period[1], true),
        endDate: convertDate(period[0], false),
    }

    //console.log("getDatePeriod: ["+moment(dates.startDate).format("DD.MM.YY")+" - "+moment(dates.endDate).format("DD.MM.YY")+"]");
    return dates;

}

const Messages = (props) => {

    const classes = useStyles();
    const gClasses = useGStyles();

    const { user, setUser } = useContext(UserContext);

    //если пользователь не авторизован редирект в "/auth/login"
    useUserRedirect();

    const [toast, setToast] = useState({open: false, severity: "error", "text": ""});
    const [hasMore, setHasMore] = useState(true);
    const [data, setData]= useState([]);
    const [sliderPeriodValue, setSliderPeriodValue] = useState([0, 6]);
    const [dataProcessMessageWithAI, setDataProcessMessageWithAI] = useState({});
    const [loadingMarkup, setLoadingMarkup] = useState(false);
    const [activeMsg, setActiveMsg] = useState(null);
    const [loadingControlProcessMessageWithAI, setLoadingControlProcessMessageWithAI] = useState(false);

    const searchRef = useRef();

    const nav = useNavigate();

    const [filterState, setFilterState] = React.useState({
        searchText: "",
        status: "new",
        period: [0,6],
        ...getDatePeriod([0,6]),
    });

    //Drawer
    const [drawer, setDrawer] = useState({
        show: false,
        height: gClasses.drawerSt,
        type: null,
        zIndex: 1200,
    });

    //Drawer FS
    const [drawerFS, setDrawerFS] = useState({
        show: false,
        type: null,
        zIndex: 1200,
    });

    useEffect(() => {

        if(user) fetchMoreData({reload: true, filter: filterState, dateRequest, fetchData: MessagesService.getItems, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"});

    }, []);


    //{reload, filter, options = {}, fetchFunction, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId}

    const onScroll = () => {
        console.log("onScroll");
        setFilterState({...filterState, scrollTop: document.querySelector("#messagesScrollableDiv").scrollTop});
    }

    const onMarkupMessage = async (id) => {
        //nav("/messages/"+id);

        const tmpDrawer = {...drawerFS, type: "loading", show: true, zIndex: 1201, data: {id}};
        setDrawerFS(tmpDrawer);

        const result = await MessagesService.getOne(id);

        if(!(result && result.status && result.data)){
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка!"});
        }else{
            setActiveMsg(result.data);
            setDrawerFS({...tmpDrawer, type: "markup_message"});
        }

    };

    const onRemoveMessage = (id) => {
        /*
        Meteor.call('methods.runAPIMethod', "chat-messages/remove", props.user, {id}, (err, result) => {

            if (err || !(result && result.status && result.data && result.data.status) ) {
                setToast({open: true, severity: "error", text: result.data && result.data.error || "Неизвестная ошибка!"});
            }else{
                setData(data.filter(d => d._id != id));
            }
        });

         */
    }

    const onToggleMarkupWithAI = (status, id) => {

        /*
        Meteor.call('methods.runAPIMethod', "chat-messages/toggle-markup-with-ai", props.user, {id, status}, (err, result) => {

            if (err || !(result && result.status && result.data && result.data.status && result.data.item) ) {
                setToast({open: true, severity: "error", text: result.data && result.data.error || "Неизвестная ошибка!"});
            }else{

                const index = data.findIndex(h => h._id === id);
                if(index >= 0) setData(replObjInArr(data, index, {markupWithAI: status}));

            }
        });
         */

    }

    const onResetPeriodFilter = () => {
        setSliderPeriodValue([0, 3]);

        const filter = {...filterState, period: [0,3]};
        setFilterState(filter);
        setDrawer({...drawer, show: false});

        fetchMoreData({reload: true, filter, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})
    }

    const onChangePeriodFilter = (event, newValue, activeThumb) => {

        if (!Array.isArray(newValue)) {
            return;
        }

        const minDistance = 1;
        let start;
        let end;

        if (activeThumb === 0) {
            start = Math.min(newValue[0], sliderPeriodValue[1] - minDistance);
            end = sliderPeriodValue[1];
        } else {
            start = sliderPeriodValue[0];
            end = Math.max(newValue[1], sliderPeriodValue[0] + minDistance);
        }

        setSliderPeriodValue([start, end]);
    }

    const onSavePeriodFilterDialog = () => {

        setDrawer({...drawer, show: false});

        const filter = {...filterState, period: sliderPeriodValue, ...getDatePeriod(sliderPeriodValue)};
        setFilterState(filter);

        fetchMoreData({reload: true, filter, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})

    }

    const onControlProcessMessageWithAI = async (reset) => {

        let action;
        switch (dataProcessMessageWithAI.status) {
            case "in_process":
                action = "stop";
                break;
            case "stopped":
                action = "start";
                break;
        }

        setLoadingControlProcessMessageWithAI(true);

        const result = await MessagesService.controlTaskProcessWithAI({action, reset});

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка!"});
            setLoadingControlProcessMessageWithAI(false);
        }else{
            setDataProcessMessageWithAI(result.data);
            setLoadingControlProcessMessageWithAI(false);
        }

    }

    const onSaveCountProcessMessageWithAI = async () => {

        const result = await MessagesService.setCountTaskProcessWithAI({count: dataProcessMessageWithAI.count});

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка!"});
        }else{
            setDataProcessMessageWithAI(result.data);
        }

    }

    const onChangeStatus = (e) => {
        const filter = {...filterState, status: e.target.value};
        setFilterState(filter);
        fetchMoreData({reload: true, filter, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})

    }

    const onCloseMarkupMessage = () => {

        //обновляем данные сообщения в списке на основе
        const index = data.findIndex(h => h._id === activeMsg._id);
        const newData = replObjInArr(data, index, {messageData: activeMsg.messageData, status: activeMsg.status || null});
        if(index >= 0) setData(newData);

        setDrawerFS({...drawerFS, show: false});
    };

    const searchHandleKeyDown = async () => {

        //осущесивляеи поиск раз в 1 секунду
        await timeout(1000);
        if(searchText == searchRef.current.value) return;
        searchText = searchRef.current.value;

        const filter = {...filterState, searchText};
        setFilterState(filter);

        //const result = await useMethod("methods.runAPIMethod", "chat-messages/normalize-search-text", props.user, {text: searchText});
        //searchTextNormalized = result && result.status && result.data && result.data.data || [];

        fetchMoreData({reload: true, filter, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})

    }

    const reLoad = () => {
        fetchMoreData({reload: true, filter: filterState, options: {scrollToPosition: 0}, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"});
    }

    const removeMarks = async (id) => {

        setLoadingMarkup(true);

        const result = await MessagesService.removeMarks({id});

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: "Ошибка разметки сообщения!"});
            setLoadingMarkup(false);
        }else{
            setActiveMsg(result.data);
            setLoadingMarkup(false);

            const index = data.findIndex(h => h._id === id);
            const newData = replObjInArr(data, index, {messageData: result.data.messageData, status: result.data.status || null});
            if(index >= 0) setData(newData);

        }

    }

    const onOpenProcessMessagesWithAI = async () => {

        const tmpDrawer = {...drawer, type: "loading", show: true, zIndex: 1201, height: gClasses.drawerH50};
        setDrawer(tmpDrawer);

        const result = await MessagesService.getTaskProcessWithAI();

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: result.error || "Неизвестная ошибка!"});
            setDrawer({...tmpDrawer, show: false});
        }else{
            setDataProcessMessageWithAI(result.data);
            setDrawer({...tmpDrawer, type: "process_messages_with_ai"});
        }
    }

    const markupMsgWithAI = async (id) => {

        setLoadingMarkup(true);

        const result = await MessagesService.markupWithAI({id});

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: (result.error || "Ошибка разметки сообщения!")});
            setLoadingMarkup(false);
        }else{
            setActiveMsg(result.data);
            setLoadingMarkup(false);

            const index = data.findIndex(h => h._id === id);
            if(index >= 0) setData(replObjInArr(data, index, {markupWithAI: result.data.markupWithAI, status: result.data.status}));

        }

    }

    const onFinishMarkup = async (id) => {

        const result = await MessagesService.finishMarkup({id});

        if (!(result && result.status) ) {
            setToast({open: true, severity: "error", text: (result.error || "Ошибка разметки сообщения!") });
        }else{
            const index = data.findIndex(h => h._id === id);
            if(index >= 0) setData(replObjInArr(data, index, {markupWithAI: null, status: "markup_completed", updatedAt: result.data.updatedAt}));

            setDrawerFS({...drawerFS, show: false});
            //props.history.push("/messages");
        }

    }

    const onSearchClear = () => {
        searchRef.current.value = "";
        searchText = "";
        setFilterState({...filterState, searchText: ""});
        searchTextNormalized = [];
        fetchMoreData({reload: true, filter: {...filterState, searchText: ""}, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})

    }

    const onOpenPeriodFilter = () => {
        setSliderPeriodValue(filterState.period);
        setDrawer({...drawer, type: "change_period_filter", height: null, show: true, zIndex: 1201})
    }


    const renderDrawerSwitch = () => {
        switch (drawer.type) {

            case "loading":
                return <CircularProgress/>;

            case "change_period_filter":
                return (
                    <>

                        <div className={clsx(classes.bottomDrawerHeader)}>

                            <Button variant="text" className={classes.bottomDrawerRemoveBtn} onClick={onResetPeriodFilter}>Сбросить</Button>


                            <p>Укажите период поиска</p>
                            <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={classes.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                        <div className={clsx(classes.bottomDrawerBody, gClasses.p16)} >

                            <Slider
                                step={1}
                                track={false}
                                value={sliderPeriodValue}
                                marks={periodFilterMarks}
                                onChange={onChangePeriodFilter}
                                min={0}
                                max={7}
                                disableSwap
                                style={{marginBottom: 80}}
                            />

                        </div>

                        <div className={classes.drawerBtnsWrapper}>
                            <Button
                                type="button"
                                variant="contained"
                                fullWidth
                                onClick={onSavePeriodFilterDialog}>
                                Сохранить
                            </Button>
                        </div>

                    </>
                )

            case "process_messages_with_ai":
                return (
                    <>

                        <div className={clsx(classes.bottomDrawerHeader)}>

                            <p>Задача по обработке сообщений ChatGPT</p>
                            <IconButton size="small" onClick={() => {setDrawer({...drawer, show: false});}} className={classes.bottomDrawerCloseBtn}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </div>

                        <div className={clsx(classes.bottomDrawerBody, gClasses.p16) }>

                            {!!dataProcessMessageWithAI?.date && <p>Дата создания: {moment(dataProcessMessageWithAI?.date).format("DD.MM.YY HH:mm")}</p>}
                            {<p>Статус: {dataProcessMessageWithAI?.status || "?"} ({dataProcessMessageWithAI?.reason || ""})</p>}
                            {<p>Обработано: {dataProcessMessageWithAI?.processed || 0}</p>}
                            {<p>Ошибок: {dataProcessMessageWithAI?.errors || 0}</p>}

                            <TextField
                                label="Обработать сообщений"
                                name="count"
                                disabled={dataProcessMessageWithAI.status == "in_process" || loadingControlProcessMessageWithAI}
                                onChange={(e) => {
                                    setDataProcessMessageWithAI({...dataProcessMessageWithAI, count: e.target.value})
                                }}
                                value={dataProcessMessageWithAI?.count}
                                type="number"
                                className={classes.formInput}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button variant="contained" disabled={dataProcessMessageWithAI.count == "" || dataProcessMessageWithAI.status == "in_process" || loadingControlProcessMessageWithAI} onClick={onSaveCountProcessMessageWithAI}>Сохранить</Button>
                                        </InputAdornment>
                                }}
                            />

                        </div>

                        <div className={classes.drawerBtnsWrapper}>

                            {!!(dataProcessMessageWithAI?.status && ["stopped", "in_process", "ready_to_start"].indexOf(dataProcessMessageWithAI.status) > -1) &&
                            <>
                                <LoadingButton
                                    type="button"
                                    variant="contained"
                                    fullWidth
                                    loading={loadingControlProcessMessageWithAI}
                                    onClick={() => onControlProcessMessageWithAI(true)}
                                >
                                    {!!(dataProcessMessageWithAI.status == "stopped") ?
                                        <span>Запустить</span>
                                    :
                                        <span>Остановить</span>
                                    }
                                </LoadingButton>
                            </>
                            }

                        </div>

                    </>
                )
        }
    }

    const renderFSDrawerSwitch = () => {
        switch (drawerFS.type) {

            case "loading":
                return <CircularProgress/>;

            case "markup_message":
                return (
                    <>
                        <div className={gClasses.drawerCatalogReCardHeader}>

                            <IconButton color="inherit" aria-label="menu" onClick={onCloseMarkupMessage}>
                                <ArrowBackIcon />
                            </IconButton>

                            <div className={classes.drawerActionButtons}>

                                <IconButton size="small" onClick={() => removeMarks(drawerFS?.data?.id)}>
                                    <DeleteIcon/>
                                </IconButton>

                                <IconButton size="small" onClick={() => markupMsgWithAI(drawerFS?.data?.id)}>
                                    <AIIcon/>
                                </IconButton>
                            </div>

                        </div>

                        {
                            !loadingMarkup?
                                <MarkupMessage
                                    toast={toast}
                                    setToast={setToast}
                                    activeMsg={activeMsg}
                                    setActiveMsg={setActiveMsg}
                                    onFinishMarkup={onFinishMarkup}
                                />
                                :
                                <div className={classes.loadingMarkupMessageWrapper}>
                                    <CircularProgress size={30} style={{marginTop: 100}}/>
                                </div>

                        }

                    </>

                )

        }
    }

    if(!user) return null;

    return (
        <div className={gClasses.wrapper}>

            <AppBar color="default" position="relative" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>

                    <Paper className={classes.msgsGroupsSearch} elevation={0}>
                        <InputBase
                            inputRef={searchRef}
                            fullWidth
                            placeholder="Поиск…"
                            onKeyDown={() => qSearch.push(searchHandleKeyDown)}
                            defaultValue={filterState.searchText || ""}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />

                        <IconButton
                            className={classes.clearSearch}
                            disabled={ (filterState.searchText == "" || !filterState.searchText) }
                            //disabled={ !(searchRef.current && searchRef.current.value && searchRef.current.value != "") }
                            onClick={() => onSearchClear()}
                            color="secondary"
                        >
                            <ClearIcon />
                        </IconButton>

                    </Paper>

                    <TextField
                        size="small"
                        select
                        value={filterState.status}
                        onChange={onChangeStatus}
                        style={{margin: "0 10px", minWidth: 250}}
                    >
                        <MenuItem value="new">Новые сообщения</MenuItem>
                        <MenuItem value="markup_with_ai">Размечено ChatGPT</MenuItem>
                        <MenuItem value="markup_completed">Размечено и проверено</MenuItem>
                        <MenuItem value="markup_with_user">В работе у пользователя</MenuItem>
                        <MenuItem value="markup_with_ai_error">Ошибка при разметке ChatGPT</MenuItem>
                        <MenuItem value="markup_in_process_with_ai">В обработке ChatGPT</MenuItem>
                    </TextField>

                    <Button variant="outlined" style={{padding: "3px 0", minWidth: 44}} onClick={onOpenPeriodFilter}>
                        {getTextPeriod(filterState.period)}
                    </Button>

                    <IconButton onClick={() => reLoad()} type="submit" className={classes.refrashButton} aria-label="search">
                        <AutorenewIcon />
                    </IconButton>

                    <IconButton type="submit" onClick={onOpenProcessMessagesWithAI}>
                        <AIIcon/>
                    </IconButton>


                </Toolbar>
            </AppBar>

            <div id="messagesScrollableDiv" className={gClasses.scrollBox} >

                <InfiniteScroll
                    dataLength={data.length}
                    scrollThreshold="700px"
                    next={() => fetchMoreData({reload: false, filter: filterState, fetchData: MessagesService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "messagesScrollableDiv"})}
                    hasMore={hasMore}
                    onScroll={() => onScroll()}
                    loader={<div className={gClasses.circularProgressWrapper}><CircularProgress/></div>}
                    scrollableTarget="messagesScrollableDiv"
                    endMessage={<div>Все данные загружены</div>}
                >
                    {data.map((item, index) => (
                        <MsgItem
                            history={props.history}
                            classes={classes}
                            key={item._id}
                            //toogleFavorite={toogleFavorite}
                            searchText={filterState.searchText}
                            //setOpenCommentDialog={setOpenCommentDialog}
                            //setOpenCommentDialogMsgId={setOpenCommentDialogMsgId}
                            user={props.user}
                            //searchTextNormalized={searchTextNormalized}
                            onMarkupMessage={onMarkupMessage}
                            onRemoveMessage={onRemoveMessage}
                            setToast={setToast}
                            onToggleMarkupWithAI={onToggleMarkupWithAI}
                            {...item}
                        />
                    ))}
                </InfiniteScroll>

            </div>

            <SwipeableDrawer
                anchor="bottom"
                open={drawer.show}
                onClose={() => {setDrawer({...drawer, show: false});}}
                onOpen={() => {}}
                style={{zIndex: drawer.zIndex}}
                className={clsx(gClasses.drawer, drawer.height)}
            >
                <div className={gClasses.drawerWrapper}>
                    <div className={gClasses.drawerFrame}>
                        {renderDrawerSwitch()}
                    </div>
                </div>

            </SwipeableDrawer>

            <Drawer
                anchor="right"
                open={drawerFS.show}
                onClose={() => {setDrawerFS({...drawerFS, show: false});}}
                className={gClasses.fullPageDrawer}
            >
                <div className={gClasses.drawerFrame}>
                    {renderFSDrawerSwitch()}
                </div>

            </Drawer>

            <CustomSnackbar toast={toast} setToast={setToast} classes={classes}/>

        </div>
    )

};

export default Messages;
