import React from 'react';
import {makeStyles} from "@mui/styles";
import {
    orange,
    deepOrange,
    lightGreen,
    lightBlue,
    lime,
    grey,
    red,
    yellow,
    deepPurple,
    green,
    blue,
    teal,
} from '@mui/material/colors';

const onePx = 0.0625;
export default makeStyles((theme) => ({

//group-msg

    groupMsgTile: {
        //maxWidth: "568px",
        margin: "16px auto",
        position: "relative",
    },
    groupMsgContent: {
        paddingBottom: 0,
    },
    groupMsgMsgText: {
        fontSize: "16px",
        lineHeight: "1.4em",
        "& span": {
            background:  yellow[500],
            fontWeight: 600,
        },
        "& p": {
            margin: "0 0 3px 0"
        }
    },
    groupMsgActions: {
        padding: "0 8px 8px 16px",
    },
    groupMsgDelimiter: {
        margin: "8px 0",
        borderTop: "1px solid #e2e2e3",
    },
    groupMsgComments: {
        fontSize: "14px",
        lineHeight: "1.4em",
        width: "100%",
    },
    groupMsgComment:{
        margin: "3px 0",
        color: "#a1a1a1"
    },
    groupMsgActionsBtn: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginLeft: "-5px",
        color: "#1976d2",
        "& svg": {
            fontSize: "1.3rem",
        }
    },
    groupMsgMarkupBtnProcess: {
        "& .MuiBadge-badge": {
            background: orange[500],
            top: 5,
            right: -5,
        },
    },
    groupMsgMarkupBtnSuccess: {
        "& .MuiBadge-badge": {
            background: green[500],
            top: 5,
            right: -5,
        },
    },
    favoriteBtn: {

    },
    groupMsgPhone: {
        "& a": {
            textDecoration: "none",
        }
    },
    msgsGroupsSearch: {
        position: 'relative',
        borderRadius: 8,
        background: "none!important",
        marginLeft: 0,
        display: "flex",
        alignItems: "center",
        "& .Mui-disabled": {
            display: "none",
        },
        width: "calc(100% - 125px)",
        marginRight: 10,
    },
    groupMsgMsgDate: {
        position: "absolute",
        right: 16,
        bottom: 4,
        fontSize: "0.9rem",
        color: grey[800],
        fontWeight: 500,
    },
    groupMsgMsgDateUpdated: {
        color: "green",
    },
    statusCompleted: {
        color: green[700],
        fontWeight: 600,
    },
    statusMarkupWithUser: {
        color: blue[200],
    },
    statusMarkupWithAI: {
        color: "orange",
        fontWeight: 600,
    },
    statusErrorMarkupWithAI: {
        color: "red",
        fontWeight: 600,
    },
    groupMsgStatus: {
        marginLeft: 15,
        marginRight: 10,
        fontSize: 14*onePx+"rem",
    },
    refrashButton: {
        padding: 3,
        marginRight: 5
    },

    // markup
    markupActions: {
        padding: 16,
        marginBottom: 20,
    },
    markupActionsWrapper: {
        position: "fixed",
        left: 0,
        top: 50,
        width: "100%",
        zIndex: 2,
    },
    markupMarkWrapper: {
        padding: "0px",
        position: "fixed",
        right: 0,
        top: "calc(50% - 252px)",
        width: 135,
        zIndex: 2,
    },
    markupMarkBtn: {
        borderRadius: 0,
        width: "100%"
    },
    markupMarkMenuBtn: {

    },
    markupMarkMenuBtnActive: {
        background: "#afadad",
    },
    markupMarkClearBtn: {
        color: "#f44336",
        width: "100%",
    },
    markupEditable: {
        background: "whitesmoke",
        padding: 16,
        borderRadius: 8,
        height: "100%",
        overflowY: "auto",
    },
    markupEditableWrapper:{
        height: "calc(100% - 109px)",
        padding: "16px",
        "& p": {
            margin: 0,
        },
        "& span[data-slate-node='text']": {

        },
    },
    markupEditableSaving: {
        display: "flex",
        alignItems: "center",
        "& .MuiCircularProgress-root": {
            marginRight: 5,
        }
    },
    loadingMarkupMessageWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: 100,
    },
    markupActionsContainer: {
        maxWidth: 720,
        margin: "auto",
        background: "#fff",
    },
    markupLeaf: {
        position: "relative",
        lineHeight: "2.5em",
        paddingTop: 12,
        "& span[data-slate-string='true']": {
            position: "relative",
            paddingTop: 12,
            zIndex: 3
        }
    },
    markupLeafName: {
        position: "absolute",
        top: 1,
        left: 0,
        fontSize: 10*onePx+"rem",
        lineHeight: "10px",
        zIndex: 2,
        width: "100%",
        textAlign: "left",
        textWrap: "nowrap",
    },
    markupLeafDeleteBtn: {
        position: "absolute",
        top: -7,
        right: -7,
        background: "#ffa0a0",
        width: 14,
        height: 14,
        zIndex: 3,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
    },
    statusSavingMarkup: {
        textAlign: "center",
        fontSize: "0.9rem",
        position: "absolute",
        bottom: "-33px",
    },
    markupMenu: {
        padding: "8px 7px 6px",
        position: "absolute",
        zIndex: 5,
        top: "-10000px",
        left: "-10000px",
        marginTop: "-6px",
        opacity: 0,
        backgroundColor: "#e1dfdf",
        borderRadius: "4px",
        transition: "opacity 0.75s",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: 294,
    },
    markupOptions: {
        padding: 16,
        height: 15,
        width: "100%",
    },

    bottomDrawerRemoveBtn: {
        position: "absolute",
        top: -1,
        left: 16,
        color: "#EB3333",
        textTransform: "inherit",
        padding: 0,
    },
    bottomDrawerHeader: {
        position: "relative",
        fontSize: 17*onePx+"rem",
        fontWeight: 600,
        height: 37,
        "& p": {
            textAlign: "center",
        }
    },
    bottomDrawerCloseBtn: {
        position: "absolute",
        top: -1,
        right: 16,
        background: "rgba(49, 67, 83, 0.07)",
        color: "rgba(39, 53, 67, 0.8)",
    },
    bottomDrawerBody: {
        height: "calc(100% - 48px)",
        //height: "calc(100% - 37px)",
        overflowY: "auto",
    },
    bottomDrawerBodyWithBottomBtns: {
        height: "calc(100% - 37px)",
        overflowY: "auto",
        paddingBottom: 77,
    },
    drawerBtnsWrapper: {
        flexWrap: "wrap",
        "width": "100%",
        "zIndex": 2,
        background: "#fff",
        borderTop: "1px solid #f2f2f2",
        //"background": "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%)",
        "position": "absolute",
        "bottom": "0px",
        "left": "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: 20,
    },


}));