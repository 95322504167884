// get and updated to codestyle from
// https://raw.githubusercontent.com/bjoerge/debounce-promise/master/index.js

function getWait(wait) {
    return (typeof wait === "function") ? wait() : wait;
}

function defer() {
    let resolve;
    let reject;
    const deferred = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    deferred.resolve = resolve;
    deferred.reject = reject;

    return deferred;
}

export default function debouncePromise(fn, wait = 0, options = {}) {
    let lastCallAt;
    let deferred;
    let timer;
    let pendingArgs = [];

    function flush() {
        const thisDeferred = deferred;
        clearTimeout(timer);

        Promise.resolve(
            options.accumulate
                ? fn.call(this, pendingArgs)
                : fn.apply(this, pendingArgs[pendingArgs.length - 1]),
        )
            .then(thisDeferred.resolve, thisDeferred.reject);

        pendingArgs = [];
        deferred = null;
    }

    return function debounced(...args) {
        const currentWait = getWait(wait);
        const currentTime = Date.now();

        const isCold = !lastCallAt || (currentTime - lastCallAt) > currentWait;

        lastCallAt = currentTime;

        if (isCold && options.leading) {
            return options.accumulate
                ? Promise.resolve(fn.call(this, [args]))
                    .then((result) => result[0])
                : Promise.resolve(fn.call(this, ...args));
        }

        if (deferred) {
            clearTimeout(timer);
        } else {
            deferred = defer();
        }

        pendingArgs.push(args);
        timer = setTimeout(flush.bind(this), currentWait);

        if (options.accumulate) {
            const argsIndex = pendingArgs.length - 1;
            return deferred.then((results) => results[argsIndex]);
        }

        return deferred;
    };
}
