import React, {Component, Fragment, useState, useEffect, useContext} from 'react';
import { useAuthRedirect } from './useAuthRedirect'
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar/AppBar";
import {useNavigate} from "react-router";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import {HappinessIcon} from "../../assets/svg-icons";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from "@mui/material/InputAdornment";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Input } from '@mui/base';
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import CustomSnackbar from "../../components/custom-snackbar/CustomSnackbar.jsx";
import AuthService from "../../services/auth.service";
import { UserContext } from "../../providers/UserProvider";
import useGStyles from "../../assets/global-styles";
import useStyles from "./styles";
import clsx from "clsx";

const Auth = ({ type }) => {

	const classes = useStyles();
	const gClasses = useGStyles();
	const { user, setUser } = useContext(UserContext);

	const [toast, setToast] = useState({open: false, severity: "error", "text": ""});

	const nav = useNavigate();

	const [data, setData]= useState({
		needRegistration: false,
		step: "phone_input",
		sendCodeBtnEnabled: false,
		errors: {},
		phone: "",
		code: "",
		name: "",
	});

	const registration = () => {

		AuthService.register(data.phone, data.name, data.code).then((res, err) => {
			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка регистрации!");
			if(error) return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});

			//если ошибок авторизации нет - сохраняем пользователя
			setUser(res);
		});

	}

	const login = ({login, password}) => {

		AuthService.login(login, password).then((res, err) => {
			const error = (err || !(res && res._id)) && (res?.error || "Неизвестная ошибка авторизации!");
			if(error) return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});

			//если ошибок авторизации нет - сохраняем пользователя
			setUser(res);
		});
	}

	const updateVerificationCode = () => {

		setData({...data, code: ""});

		AuthService.updateVerificationCode({phone: data.phone}).then((res, err) => {
			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			setToast({open: true, severity: error?"error":"success", closeTime: 10000, text: error || res.message, widthAuto: true});
			return;
		});

	}

	const onChangeCode = (event) => {

		const code = event.target.value.replace(/[^+\d]/g, '').slice(0,4);
		let tmp = {...data, code, errors: {...data.errors, code: null}};

		if(code.length == 4){

			setData({...tmp, codeChecking: true});

			AuthService.checkVerificationCode({phone: data.phone, code}).then((res, err) => {
				const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
				if(error) {
					setData({...tmp, errors: {...tmp.errors, code: <div><p>{res && res.error || "Неизвестная ошибка проверки кода"}</p><p onClick={updateVerificationCode} style={{color: "#4CAF50"}}> Запросить новый код.</p></div>}});
					return;
				}

				//если пользователь уже зарегистрирован
				if(!res.needRegistration){
					login({login: data.phone, password: code});
					return;
				}

				//если требуется регистрация
				setData({...tmp, step: "registration", codeChecking: false});

			});

			/*
			Meteor.call('methods.runAPIRegMethod', "user/check-code", {phone: data.phone, code}, (err, result) => {

				if (err || !(result && result.status && result.data && result.data.status)) {
					setData({...tmp, errors: {...tmp.errors, code: <div><p>{result.data && result.data.error || "Неизвестная ошибка проверки кода"}</p><p onClick={updateVerificationCode} style={{color: "#4CAF50"}}> Запросить новый код.</p></div>}});
					return;
				}

				//если пользователь уже зарегистрирован
				if(!result.data.needRegistration){
					login({phone: data.phone, code});
					return;
				}

				//если требуется регистрация
				setData({...tmp, step: "registration", codeChecking: false});
			});
			 */

		}else{
			setData(tmp);
		}
	}

	const onChangeName = (event) => {
		const name = event.target.value;
		setData({...data, name});
	}

	const onChangePhone = (event) => {
		let phone = event.target.value.replace(/[^+\d]/g, '');
		phone = (phone[0] == "8"?"7"+phone.slice(1,11):phone.slice(0, 11));
		setData({...data, phone, sendCodeBtnEnabled: !!(phone.length == 11)});
	}

	const toCodeEntryStep = async () => {

		let tmp = {...data, step: "enter_code", code: ""};
		setData(tmp);

		AuthService.updateVerificationCode({phone: data.phone}).then((res, err) => {
			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			setToast({open: true, severity: error?"error":"success", closeTime: 10000, text: error || res.message, widthAuto: true});
			return;
		});

	}

	const renderContent = () => {
		switch (data.step) {

			case "phone_input":

				return (
					<>
						<TextField
							placeholder="7XXXXXXXXX"
							type="text"
							onChange={onChangePhone}
							value={data.phone}
							className={classes.sendCodeInput}
							helperText={data.errors.phone}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && e.target.value.length == 11) toCodeEntryStep();
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">+</InputAdornment>
								),
								endAdornment:
									(
										<InputAdornment position="end">
											<IconButton onClick={toCodeEntryStep} color="primary" disabled={!data.sendCodeBtnEnabled} >
												<ArrowForwardIosIcon/>
											</IconButton>
										</InputAdornment>
									)
							}}
						/>
					</>
				);

			case "enter_code":
				return (
					<div className={classes.enterCodeItem}>
						<Input
							placeholder="• • • •"
							type="number"
							onChange={onChangeCode}
							value={data.code}
							//className={gClasses.aoInput}
							disabled={data.codeChecking}
						/>
						{!!(data.code?.length == 4) &&  <div className={classes.inputHelperText}>{data.errors.code || null}</div>}

						{data.codeChecking &&
						<div className={classes.inputHelperText}>
							<CircularProgress/>
						</div>
						}
					</div>
				);

			case "registration":
				return (
					<div>

						<div className={classes.regHeader}>Познакомимся?<HappinessIcon style={{marginLeft: 10, color: "#4CAF50"}}/></div>

						<TextField
							placeholder="Ваше имя"
							type="text"
							onChange={onChangeName}
							value={data.name}
							helperText={data.errors.name}
							className={gClasses.formInput}
						/>


						<div className={classes.regBtnsWrapper}>
							<Button
								type="button"
								variant="contained"
								startIcon={<DirectionsRunIcon/>}
								fullWidth
								disabled={!data.name}
								onClick={registration}>
								Начать работу
							</Button>
						</div>

					</div>
				);

		}
	}

	//в случае авторизации (если в state появляется user) производит редирект в /
	useAuthRedirect();

	//если идет загрузка пользователя или пользователь уже авторизован
	if(user) return null;

	return (
		<div className={gClasses.wrapper}>

			<AppBar color="default" position="relative" className={clsx(gClasses.appBar, gClasses.appBarReCard)}>

				<Toolbar className={gClasses.toolbar}>

					{!!(data.step == "phone_input") &&
						<div className={gClasses.toolbarLogo}>
							<img src="/svg/logo.svg" onClick={() => nav('/')}/>
						</div>
					}

					{!!(["enter_code", "registration"].indexOf(data.step) > -1) &&
					<IconButton color="inherit" aria-label="menu" onClick={() => setData({...data, step: "phone_input"})}>
						<ArrowBackIcon />
					</IconButton>
					}

				</Toolbar>
			</AppBar>

			<div className={clsx(gClasses.appContentwrapper, classes.authWrapper)}>

				<Grid
					style={{height: "100%"}}
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					{renderContent()}
				</Grid>

			</div>

			<CustomSnackbar toast={toast} setToast={setToast}/>

		</div>
	)
}

export default Auth
