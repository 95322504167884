import {useParams} from "react-router";
import React, {useContext, useEffect, useState, useCallback, Component, Fragment, useRef, useMemo} from "react";
import MessagesService from "../../../services/messages.service";
import {UserContext} from "../../../providers/UserProvider";
import {useUserRedirect} from "../../../utils/useUserRedirect";
import useGStyles from "../../../assets/global-styles";
import useStyles from "../styles";
import _ from "lodash";
import moment from 'moment';
import { omit } from 'lodash';
import isHotkey from 'is-hotkey';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from "@mui/material/Container";
import clsx from "clsx";
import { Editable, withReact, useSlate, Slate, ReactEditor, } from 'slate-react';
import { Editor, createEditor, Transforms, Descendant, Range} from 'slate';
import { withHistory } from 'slate-history';
import debouncePromise from "../../../utils/debounce-promise";
import {mapToObject, useToggle} from "../../../utils/functions";
import Button from '@mui/material/Button';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';

import {
    AreaIcon,
    RoomsIcon,
    RollerIcon,
    HouseNamesIcon,
    LocationIcon,
    ReTypeIcon,
    PriceIcon,
    CommissionIcon,
    FloorIcon,
    ReStatusIcon,
    MortgageIcon,
    CommunicationsIcon,
    LandAreaIcon,
    AIIcon,
    FinishIcon,
    WarningIcon,
    BadIcon,
} from "../../../assets/svg-icons";
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';

const getIcon = (icon) => {
    switch (icon) {
        case "ReTypeIcon": return <ReTypeIcon/>;
        case "HouseNamesIcon": return <HouseNamesIcon/>;
        case "LocationIcon": return <LocationIcon/>;
        case "RoomsIcon": return <RoomsIcon/>;
        case "AreaIcon": return <AreaIcon/>;
        case "PriceIcon": return <PriceIcon/>;
        case "CommissionIcon": return <CommissionIcon/>;
        case "FloorIcon": return <FloorIcon/>;
        case "RollerIcon": return <RollerIcon/>;
        case "ReStatusIcon": return <ReStatusIcon/>;
        case "MortgageIcon": return <MortgageIcon/>;
        case "CommunicationsIcon": return <CommunicationsIcon/>;
        case "LandAreaIcon": return <LandAreaIcon/>;
        case "BadIcon": return <BadIcon style={{color: "#000"}}/>;
        case "BlockIcon": return <BlockIcon style={{color: "#000"}}/>;
    }
}

const entities = [
    { "name": "reType", "ru": "Объект", "ruAbrr": "объект", "color": "#fce4d4", "icon": "ReTypeIcon", "matchAI": false, "descAI": "тип объекта (искаль только: дом, квартира, жилое помещение, апартаменты, ЖП, нежилое помещение)"},
    { "name": "houseName", "ru": "ЖК", "ruAbrr": "жк", "color": "#d8f8c4", "icon": "HouseNamesIcon", "matchAI": false, "descAI": "жилой комплекс (название начинается с ЖК, АК)"},
    { "name": "address", "ru": "Адрес", "ruAbrr": "адрес", "color": "#fcfcac", "icon": "LocationIcon", "matchAI": true, "descAI": "адрес"},
    { "name": "rooms", "ru": "Комнаты", "ruAbrr": "комн.", "color": "#def4fe", "icon": "RoomsIcon", "matchAI": true, "descAI": "количество комнат"},
    { "name": "area", "ru": "Площадь", "ruAbrr": "S", "color": "#dcdcfc", "icon": "AreaIcon", "matchAI": true, "descAI": "площадь недвижимости"},
    { "name": "price", "ru": "Цена", "ruAbrr": "₽", "color": "#c4fccd", "icon": "PriceIcon", "matchAI": true, "descAI": "цена"},
    { "name": "commission", "ru": "Комиссия", "ruAbrr": "(₽)", "color": "#fce4ab", "icon": "CommissionIcon", "matchAI": true, "descAI": "комиссия (число обычно указывается за ценой в скобках)"},
    { "name": "floor", "ru": "Этаж", "ruAbrr": "э", "color": "#fdccd4", "icon": "FloorIcon", "matchAI": true, "descAI": "этаж"},
    { "name": "furnish", "ru": "Отделка", "ruAbrr": "рем.", "color": "#eccdfc", "icon": "RollerIcon", "matchAI": true, "descAI": "отделка/ремонт (ремонт, РМТ, черновая...)"},
    { "name": "mortage", "ru": "Ипотека", "ruAbrr": "ипот.", "color": "#fcfca4", "icon": "MortgageIcon", "matchAI": true, "descAI": "есть ли ипотека"},
    { "name": "landArea", "ru": "S земли", "ruAbrr": "S зем.", "color": "#ceeeff", "icon": "LandAreaIcon", "matchAI": true, "descAI": "площадь участка в сотках"},
    { "name": "rent", "ru": "Аренда", "ruAbrr": "аренда", "color": "#85c9fb", "icon": "BadIcon", "matchAI": true, "descAI": "если сдается в аренду"},
    { "name": "exclude", "ru": "Исключить", "ruAbrr": "stop", "color": "#e38484", "icon": "BlockIcon", "matchAI": false}
]

const MARKS_FEATURES = entities.map(e => {
    e.icon = getIcon(e.icon);
    return e;
});


const createFeatures = (FEATURES) => {

    //формирование массива ключ: имя элемента (MARKS_FEATURES[i].name) данные: исходные элементы массив
    const FEATURE_NAMES = mapToObject(FEATURES, f => [f.name, f]);

    //формирование массива ключ:  mod+i, данные - имя элемента
    const HOTKEYS = mapToObject(FEATURES, (f, i) => ([`mod+${i}`, f.name]));

    const Leaf = ({ attributes, children, leaf }) => {

        const format = FEATURES.find(f => leaf[f.name]);

        if (format) {
            attributes = { ...attributes, style: { ...attributes.style, backgroundColor: format.color } };
        }

        const classes = useStyles();

        /*
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }
        */

        ///{!!format?.ru && <i className={classes.markupLeafName}>{format.ruAbrr}</i>}

        return <span className={classes.markupLeaf} {...attributes}
        >
            {children}
            {!!format?.ru && <i className={classes.markupLeafName}>{format.ruAbrr}</i>}
        </span>;
    };

    return { FEATURES, FEATURE_NAMES, HOTKEYS, Leaf };
};

//в выделенной области - если есть маркер удаляет его, если его нет, то создает новый
const toggleMark = (editor, format) => {

    const marks = Editor.marks(editor) || {};
    //удаляет маркер с выделенной области
    Object
        .keys(marks || {})
        .forEach(mark => {
            return Editor.removeMark(editor, mark)
        });

    //если маркера не было, то добавляет новый
    if (!marks[format]) {
        Editor.addMark(editor, format, true);
    }
};

const removeAllMarks = (editor) => {
    const marks = Editor.marks(editor) || {};

    if(!Object.keys(marks || {}).length){
        console.log("Нет выделенных marks");
        return;
    }
    //удаляет маркер с выделенной области
    Object
        .keys(marks || {})
        .forEach(mark => {
            console.log("removeAllMarks удаляю mark"+mark);
            return Editor.removeMark(editor, mark)
        });
};

const Element = ({ attributes, children, element }) => {
    return <p {...attributes}>{children}</p>;
};

// генерирует 1 кнопку Mark, получает от editor выделен ли в редакторе текущий маркер с именем format
const MarkButton = ({classes, format, id, color, children }) => {
    const editor = useSlate();
    const active = isMarkActive(editor, format);
    return (
        <Button
            className={classes.markupMarkBtn}
            style={active ? {} : { backgroundColor: color }}
            type={active ? 'primary' : 'default'}
            onClick={() => toggleMark(editor, format)}
        >
            {/*{icon}*/}
            {id}. {children}
        </Button>
    );
};

const MarkButtonMenu = ({classes, name, format, icon }) => {
    const editor = useSlate();
    const active = isMarkActive(editor, format);
    return (
        <Tooltip title={name}>
            <IconButton
                component="span"
                //size="small"
                className={clsx(classes.markupMarkMenuBtn, (active?classes.markupMarkMenuBtnActive:null)) }
                onClick={() => toggleMark(editor, format)}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

const RemoveMarkButtonMenu = ({classes}) => {
    const editor = useSlate();
    return (
        <Tooltip title="Удалить выделение">
            <IconButton
                style={{color: "red"}}
                component="span"
                className={classes.markupMarkMenuBtn }
                onClick={() => removeAllMarks(editor)}
            >
                <DeleteForeverIcon/>
            </IconButton>
        </Tooltip>
    );
};

//преобразует формат массива разметки редактора в массив для метода сохранения данных
const slateToMarks = value => value[0].children.map(m => {
    const type = Object.keys(m).find(k => m[k] === true);
    return {
        //сохраняет текст
        //text: m.text,
        //добавляем измененный type
        type,
        //исключаем text и type
        ...(omit(m, [/*'text',*/type])),
    };
});

//возвращает true если в выделенной области редактора есть маркер с именем format
const isMarkActive = (editor, format) => {
    //возвращает объект с именем маркера который сейчас выделен в редакторе
    const marks = Editor.marks(editor);
    return marks  && marks[format] && marks[format] == true ? true : false;
    //return marks ? marks[format] === true : false;
};

//формирует массив данных в формате редактора
const createState = (text, marks) => {

    const marksToSlate = (marks) => [{
        type: 'paragraph',
        children: marks.map(({ text, type, ...subtypes }) => {
            const value = {text, ...subtypes }; //id: Random.id(),
            if (type) value[type] = true;
            return value;
        }),
    }];

    const result = marks ? marksToSlate(marks) : marksToSlate([{ text }]);

    return result;
};

const renderElement = props => <Element {...props} />;

const isFormatActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n[format] === true,
        mode: 'all',
    })
    return match?"true":"false";
}

const toggleFormat = (editor, format) => {
    const isActive = isFormatActive(editor, format);
    Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true },
        { match: Text.isText, split: true }
    )
}

const FormatButton = ({ format, text }) => {
    const editor = useSlate();
    return (
        <Button
            reversed
            active={isFormatActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleFormat(editor, format)
            }}
        >
            {text}
        </Button>
    )
}

const HoveringToolbar = ({classes, features}) => {

    const ref = useRef(HTMLDivElement || null);
    const editor = useSlate();

    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;

        if (!el) {
            return
        }

        if (!selection || !ReactEditor.isFocused(editor) || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
            el.removeAttribute('style');
            return
        }

        const ww = window.innerWidth;
        const domSelection = window.getSelection();
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();
        let left = rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2;
        let top = rect.top + window.pageYOffset - el.offsetHeight;

        //если окно выходит за границы экрана (вправо)
        if( (left+el.offsetWidth) > (ww-20) ) left = ww - el.offsetWidth - 20;
        if(left < 20) left = 20;

        el.style.opacity = '1';
        el.style.top = `${top}px`;
        el.style.left = `${left < 10?10:left}px`;
    });

    return (

        <Box>
            <Box
                ref={ref}
                className={classes.markupMenu}
            >
                {features.map((f, i) => (
                    <MarkButtonMenu
                        name={f.ru}
                        key={f.name}
                        format={f.name}
                        id={i}
                        color={f.color}
                        classes={classes}
                        icon={f.icon}
                    />
                ))}

                <RemoveMarkButtonMenu classes={classes}/>

            </Box>
        </Box>
    )
}

const ParserEditor = ({_id, text, marks, features, onSave, setToast, classes, markupMsgWithAI }) => {

    console.log("re-render ParserEditor");

    const [changed, setChanged] = useState(false);
    //let value = createState(text, marks);
    const [value, setValue] = useState(() => createState(text, marks));

    const editor = useMemo(() => withHistory(withReact(createEditor())), []);


    const { FEATURES, HOTKEYS, Leaf } = useMemo(() => createFeatures(features), []);

    const save = useMemo(() => debouncePromise(async (state) => {
        return onSave(slateToMarks(state))
            .then(() => setChanged(false))
            .catch(err => setToast({open: true, severity: "error", text: 'Не могу сохранить:' + err.message || "Неизвестная ошибка загрузки данных!"}) );
    }, 2000), [onSave]);

    const onKeyDown = event => {
        // dont update doc on any key, so make it readonly, but allow arrows
        if (!event.key.startsWith('Arrow')) {
            event.preventDefault();
        }

        if (isHotkey('mod+s', event)) save();
        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
            }
        }
    };

    const onChange = changedValue => {
        if (changedValue !== value) {
            console.log("onChange changedValue=", changedValue);
            setValue(changedValue);
            //value = changedValue;
            save(changedValue);
            setChanged(true);
        }
    };

    return (
        <>

            <Box className={classes.markupOptions}>
                {changed &&
                <div className={classes.markupEditableSaving}>
                    <CircularProgress size={15}/> Обработка...
                </div>
                }
            </Box>

            <Slate editor={editor} value={value} onChange={onChange}>

                <div className={classes.markupEditableWrapper}>
                    <HoveringToolbar classes={classes} features={FEATURES}/>
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={props => <Leaf {...props} />}
                        autoFocus
                        className={classes.markupEditable}
                        //onKeyDown={onKeyDown}
                    />
                </div>

            </Slate>
        </>
    );
};

const ParserMarkupEditor = ({_id, messageData, onSave, markupMsgWithAI, setToast, classes }) => {

    return (
        <>
            <ParserEditor
                editorType={"marks"}
                features={MARKS_FEATURES}
                key={_id}
                text={messageData.normalizedMessage}
                marks={messageData.marks}
                onSave={(...p) => onSave('marks', ...p)}
                setToast={setToast}
                classes={classes}
                markupMsgWithAI={markupMsgWithAI}
                _id={_id}
            />
        </>
    );
};


export default (props) => {

    //если пользователь не авторизован редирект в "/auth/login"
    useUserRedirect();

    const {
        setToast,
        onFinishMarkup,
        activeMsg,
        setActiveMsg,
    } = props;

    const classes = useStyles();
    const gClasses = useGStyles();

    //скорее всего используется для перерендеринга компонента
    const [, toggleUpdate] = useToggle(false);

    const onSave = useCallback(async (editorType, marks) => {

        const result = await MessagesService.saveMarks({id: activeMsg._id, type: editorType, marks});

        if (!(result && result.status && result.data) ) {
            setToast({open: true, severity: "error", text: (result.error || "Не удалось сохранить разметку сообщения с id: "+activeMsg._id) })
            toggleUpdate();
        }else{
            setActiveMsg(result.data);
            toggleUpdate();
            //setToast({open: true, severity: "success", text: "Сохранено" });
            //console.log("Сохранено id=", activeMsg._id);
        }


    }, [activeMsg]);

    const markupMsgWithAI = (id) => {

        /*
        Meteor.call("methods.runAPIMethod", "chat-messages/markup-with-ai", props.user, {id}, (err, result) => {

            if (err || !(result && result.status && result.data && result.data.status) ) {
                setToast({open: true, severity: "error", text: "Ошибка разметки сообщения!"});
                toggleUpdate();
            }else{
                setActiveMsg(result.data.item);
                //setToast({open: true, severity: "success", text: "Сообщение успешно размечено ИИ!"});
                toggleUpdate();
            }

        });
         */
    }


    return (
        <div className={gClasses.appContent}>

            <ParserMarkupEditor
                onSave={onSave}
                setToast={setToast}
                classes={classes}
                markupMsgWithAI={markupMsgWithAI}
                {...activeMsg}
            />

            <div style={{display: "flex", justifyContent: "center", margin: 20}}>
                <Button variant="contained" startIcon={<DoneIcon/>} onClick={() => onFinishMarkup(activeMsg._id)}>
                    Сообщение размечено
                </Button>
            </div>

        </div>
    )

}