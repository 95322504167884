import React, {useContext, useEffect, useState, useRef} from "react";
import moment from 'moment';
import each from 'lodash/each';
import identity from 'lodash/identity';

export const randomInteger = (min, max) => {
    // случайное целое число от min до (max+1)
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
};

export const rejectTimeout = (ms) => {
    return new Promise( (resolve, reject) => setTimeout(() => reject(new Error('timeout')), ms));
}

export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getTextDate = (date, countDaysAgo) => {

    const dateEndDay = moment(date).endOf("day");
    const textDate = moment(date).format("DD.MM.YY");
    const days = moment().diff(dateEndDay, 'days');
    const today = moment().format("DD.MM.YY") == textDate;

    if(today) {
        return "Сегодня в "+moment(date).format("HH:mm");
    }else if(days == 0){
        return "Вчера в "+moment(date).format("HH:mm");
    }else if(days == 1){
        return "Позавчера в "+moment(date).format("HH:mm");
    }else if(days < 5){
        return days+" дня назад";
    }else if(days < (countDaysAgo+1)){
        return days+" дней назад";
    }else{
        return textDate;
    }

}

export const mapToObject = (from, iteratee = identity) => {
    const result = {};
    each(from, (value, key) => {
        const [k, v] = iteratee(value, key);
        if (v !== undefined)
            result[k] = v;
    });
    return result;
}

export const useToggle = (initial = false) => {
    const [state, setState] = useState(initial);
    return [state, val => setState(s => ((typeof val === 'boolean') ? val : !s))];
};

export const replObjInArr = (array, index, insert) => {
    //если объект не найден в массиве
    if(index < 0) return array;
    return [...array.slice(0, index), {...array[index], ...insert}, ...array.slice(index+1)];
}