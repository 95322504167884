import React, {Component, Fragment, useState, useEffect, useRef} from 'react';
import {getTextDate} from "../../../utils/functions";
import IconButton from "@mui/material/IconButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ArchitectureIcon from '@mui/icons-material/Architecture';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import htmlToReact from 'html-react-parser';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import clsx from "clsx";
import {useParams} from "react-router";

let searchTextNormalized = [];

const getMessageTextStatus = (status) => {

    switch (status) {

        case "markup_completed":
            return "✓ Размечено и проверено";

        case "markup_with_user":
            return "В работе";

        case "markup_with_ai":
            return "Размечено ChatGPT";

        case "markup_with_ai_error":
            return "× Ошибка при разметке ChatGPT";

        case "markup_in_process_with_ai":
            return "В обработке ChatGPT";

        default:
            return "Не размечено";

    }

}

export default (props) => {

    let {
        classes,
        date,
        updatedAt,
        messageData,
        notes,
        inFavorites,
        _id,
        toogleFavorite,
        searchText = "",
        onMarkupMessage,
        onRemoveMessage,
        status,
    } = props;

    let {message, phone, textArr} = messageData;

    if(searchText) {

        if(textArr && textArr.length){

            //цикл по словам сообщения
            textArr.map(wordData => {

                //цикл по словам поиска
                for (let i = 0; i < searchTextNormalized.length; i++) {
                    //цикл по нормализованным частям (если их несколько) слова
                    for (let j = 0; j < wordData.normalizedWord.length; j++) {
                        //если в слове сообщения есть вхождения слова из поиска то маркеруем
                        if( wordData.normalizedWord[j].indexOf(searchTextNormalized[i]) > -1 ){
                            try{
                                message = message.replace(new RegExp(wordData.word, 'gi'), "<span>$&</span>");
                            }catch (e) {
                                console.log("Ошибка маркировки слова _id: "+_id+" word="+wordData.word)
                            }
                        }
                    }
                }

            });

        }

    }

    //формируем переносы
    message = "<p>"+message.replace(/\n/gi, "</p><p>")+"</p>";

    //формируем дату
    date = getTextDate(updatedAt || date, 15);

    const getStatusStyle = (status) => {

        switch (status) {

            case "markup_completed":
                return classes.statusCompleted;

            case "markup_with_ai":
                return classes.statusMarkupWithAI;

            case "markup_with_ai_error":
                return classes.statusErrorMarkupWithAI;

            case "markup_with_user":
                return classes.statusMarkupWithUser;
            case "markup_in_process_with_ai":
            default:
                return null;

        }
    }

    return (
        <Card className={classes.groupMsgTile}>
            <CardContent className={classes.groupMsgContent}>

                <Grid
                    container
                    alignItems="center"
                    className={classes.info}
                    justifyContent="space-between"
                >
                    <div className={clsx(classes.groupMsgPhone, getStatusStyle(status))}>
                        {getMessageTextStatus(status)}
                    </div>

                    <div className={classes.status}>
                        {
                            inFavorites?
                                <IconButton className={classes.favoriteBtn} color="inherit" aria-label="menu" onClick={() => toogleFavorite(_id, false)}>
                                    <FavoriteIcon color="primary"/>
                                </IconButton>
                                :
                                <IconButton className={classes.favoriteBtn} color="inherit" aria-label="menu" onClick={() => toogleFavorite(_id, true)}>
                                    <FavoriteBorderIcon color="primary"/>
                                </IconButton>

                        }

                        <Button
                            size="small"
                            variant={(status == "markup_completed"?"outlined":"contained")}
                            startIcon={<ArchitectureIcon />}
                            onClick={() => onMarkupMessage(_id)}
                        >
                            {(status == "markup_completed"?"Открыть":"Разметить")}
                        </Button>

                    </div>

                </Grid>

                <div className={classes.groupMsgMsgText}>
                    {htmlToReact(message)}
                </div>

                {
                    notes && notes.length?
                        <div className={classes.groupMsgComments}>
                            {
                                notes.map((note, i) => <p key={i} className={classes.groupMsgComment}>{moment(note.date).format("DD.MM.YY")+(note.publicComment?" (публ.)":"")+": "+note.text}</p>)
                            }
                        </div>
                        :
                        null
                }

                <div className={classes.groupMsgDelimiter}/>

            </CardContent>

            <CardActions className={classes.groupMsgActions}>

                <Button
                    style={{color: "red", borderColor: "red"}}
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => onRemoveMessage(_id)}
                >
                    Удалить
                </Button>

                <span className={clsx(classes.groupMsgMsgDate, (updatedAt?classes.groupMsgMsgDateUpdated: null))}>{date}{updatedAt?" (Сохранено)":""}</span>

            </CardActions>

        </Card>
    )

}
