import instance from "../api/axios.api";

const MessagesService = {

    async getItems(options) {
        const { data } = await instance.post("/messages/get-items", {...options});
        return data;
    },

    async getTaskProcessWithAI() {
        const { data } = await instance.post("/messages/get-task-process-with-ai");
        return data;
    },

    async getOne(id) {
        const { data } = await instance.post("/messages/get-one", {id});
        return data;
    },

    async saveMarks(options) {
        const { data } = await instance.post("/messages/save-marks", options);
        return data;
    },

    async finishMarkup(options) {
        const { data } = await instance.post("/messages/finish-markup", options);
        return data;
    },

    async removeMarks(options) {
        const { data } = await instance.post("/messages/remove-marks", options);
        return data;
    },

    async markupWithAI(options) {
        const { data } = await instance.post("/messages/markup-with-ai", options);
        return data;
    },

    async setCountTaskProcessWithAI(options) {
        const { data } = await instance.post("/messages/set-count-task-process-with-ai", options);
        return data;
    },

    async controlTaskProcessWithAI(options) {
        const { data } = await instance.post("/messages/control-task-process-with-ai", options);
        return data;
    },



};
export default MessagesService;
